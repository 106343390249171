<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">未读人员列表</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
    </div>
    <div class="list">
      <ul class="list_content">
        <li class="left">{{object.dptName}}</li>
        <li class="center">
          <span>已读 ({{object.msgReadNum}})</span>
          <span>|</span>
          <span>未读 ({{object.msgUnReadNum}})</span>
        </li>
      </ul>
    </div>
    <div class="detail">
      <table>
        <tr class="title">
          <th>姓名</th>
          <th>{{dptType===1?'班主任':'主管人'}}</th>
          <th>阅读情况</th>
        </tr>
        <tr class="content" v-for="item in list" :key="item.id">
          <td>{{item.userName}}</td>
          <td>{{item.manageName}}</td>
          <td>
            <span style="color:yellowgreen" v-if="item.status===1">已读</span>
            <span style="color:red" v-if="item.status===0">未读</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { getMsgReadDetailAPI } from "@/api/inform";
import cancel from "@/assets/cancel.png";
export default {
  data() {
    return {
      dptId: "",
      msgId: "",
      list: [],
      object: {},
      cancel,
      isClassManager: false,
      dptType: 1,
    };
  },
  created() {
    this.dptId = JSON.parse(this.$route.query.item).dptId;
    this.dptType = JSON.parse(this.$route.query.item).dptType;
    this.msgId = this.$route.query.msgId;
    this.object = JSON.parse(this.$route.query.item);
    this.getMsgReadDetailFn();
    this.isClassManager = JSON.parse(
      sessionStorage.getItem("personInfo")
    ).isClassManager;
    console.log(this.dptType);
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    async getMsgReadDetailFn() {
      const res = await getMsgReadDetailAPI(this.msgId, this.dptId);
      if (res.code === 0) {
        this.list = res.data.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #ffffff;
  height: 100vh;
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;
    z-index: 99;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .list {
    padding-top: 3rem;
    .list_content {
      width: 90%;
      margin: 0 auto;
      display: flex;
      height: 5rem;
      line-height: 5rem;
      .left {
        width: 50%;
        text-indent: 1rem;
        color: black;
      }
      .center {
        width: 50%;
        text-align: right;
      }
    }
  }
  .detail {
    width: 90%;
    margin: 0 auto;
    table {
      width: 100%;
      border-spacing: 0;
      color: black;
      tr {
        height: 2rem;
        text-align: center;
        th {
          font-weight: 700;
        }
      }
    }
    .title {
      background: #eeeeee;
    }
    .content:nth-child(odd) {
      background: #eeeeee;
    }
  }
}
</style>